<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5107 15.7197L21.0001 21.0002"
      stroke="#3A405A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="parent-hover:fill-primary"
    />
    <path
      d="M1 9.63475C1 14.4036 4.72519 18.2695 9.32046 18.2695C11.622 18.2695 13.7055 17.2997 15.2118 15.7323C16.7129 14.1704 17.6409 12.0151 17.6409 9.63475C17.6409 4.86591 13.9157 1 9.32046 1C4.72519 1 1 4.86591 1 9.63475Z"
      stroke="#3A405A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="parent-hover:fill-primary"
    />
  </svg>
</template>

<style scoped>
.parent-loop:hover .parent-hover\:fill-primary {
  stroke: #FE7F2D;
}
</style>
