<template>
  <div>
    <div
      class="drawer"
      ref="refDrawer"
      :class="{ 'is-open': isOpen, 'is-visible': isVisible }"
    >
      <div
        class="drawer__overlay"
        :style="{ transitionDuration: `${speed}ms` }"
      ></div>
      <div
        class="drawer__content"
        :style="{
          maxWidth: maxWidth,
          transitionDuration: `${speed}ms`,
          backgroundColor: backgroundColor,
        }"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onClickOutside } from "@vueuse/core";
const props = defineProps({
  isOpen: {
    type: Boolean,
    required: false,
    default: true,
  },
  maxWidth: {
    type: String,
    required: false,
    default: "400px",
  },

  speed: {
    type: Number,
    required: false,
    default: 300,
  },

  backgroundColor: {
    type: String,
    required: false,
    default: "#fafafa",
  },
});

const isVisible = ref(props.isOpen);
const isOpen = computed(() => props.isOpen);
const isTransitioning = ref(false);

watch(isOpen, (val) => {
  isTransitioning.value = true;

  if (val) {
    toggleBackgroundScrolling(true);
    isVisible.value = true;
  } else {
    toggleBackgroundScrolling(false);
    setTimeout(() => (isVisible.value = false), props.speed);
  }

  setTimeout(() => (isTransitioning.value = false), props.speed);
});

const refDrawer = ref(false);
const emit = defineEmits(["close"]);

onClickOutside(refDrawer, () => {
  if (isTransitioning.value) {
    emit("close");
  }
});

function toggleBackgroundScrolling(enable) {
  const body = document.querySelector("body");

  body.style.overflow = enable ? "hidden" : null;
}

function closeDrawer() {
  if (!isTransitioning.value) {
    emit("close");
  }
}
</script>

<style scoped>
.drawer {
  visibility: hidden;

  &.is-visible {
    visibility: visible;
  }

  &.is-open {
    .drawer__overlay {
      opacity: 0.5;
    }

    .drawer__content {
      transform: translateX(0);
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 200;
    opacity: 0;
    transition-property: opacity;
    background-color: #000000;
    user-select: none;
  }

  &__content {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
    overflow: auto;
    transition-property: transform;
    display: flex;
    flex-direction: column;
    transform: translateX(100%);
    box-shadow: 0 2px 6px #777;
  }
}
</style>
